import React from 'react';

// data
import { agency as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import SectionTitle from '@components/atoms/SectionTitle';
import Cta from '@components/molecules/Cta';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ItemBullet from '@components/molecules/items/ItemBullet';
import ItemImage from '@components/molecules/items/ItemImage';
import ItemLogo from '@components/molecules/items/ItemLogo';
import ListClients from '@components/organisms/ListClients';
import Panel from '@components/organisms/Panel';

// assets
import imgMessieurs from '@assets/images/partners/messieurs.svg';
import imgSigma_legal from '@assets/images/partners/sigma_legal.svg';
// import imgRamon_pedro from '@assets/images/partners/ramon_pedro.svg';
import imgAnalyser from '@assets/images/analyser.svg';
import imgSimplifier from '@assets/images/simplifier.svg';
import imgAmplifier from '@assets/images/amplifier.svg';
import imgEight from '@assets/images/eight.svg';
import imgStrategy from '@assets/images/ico-strategy.svg';
import imgBranding from '@assets/images/ico-branding.svg';
import imgSolutions from '@assets/images/solutions.svg';

// --------------------------------
// #region Data
// --------------------------------

const language = 'en';

const pageClass = 'page-agency';

const footerRelatedLinks = [
	{ title: 'Team', url: '/en/team/' },
	{ title: 'Work', url: '/en/projects/' },
];

const servicesList = [
	{
		image: imgStrategy,
		title: 'Strategy',
		list: [
			'Diagnosis and audit',
			'Research',
			'Positioning',
			'Acquisition and sales',
			'Customer engagement',
			'Brand and information architecture',
			'Ideation, naming and copywriting',
		],
		link: {
			title: 'Discover',
			url: '/en/digital-strategy-agency/',
		},
	},
	{
		image: imgBranding,
		title: 'Branding',
		list: [
			'Art direction',
			'Logo and visual identity',
			'Visual language definition',
			'Collateral production',
			'Motion design',
			'Brand personality and messaging',
			'Brand guidelines',
			'Campaigns',
		],
		link: {
			title: 'Discover',
			url: '/en/swiss-branding-agency/',
		},
	},
	{
		image: imgSolutions,
		title: 'Web solutions',
		list: [
			'Users research',
			'UX & UI design',
			'Prototyping and optimization',
			'Design Systems & dynamic style guides',
			'Digital products and services',
			'Digital experience',
			'Websites & eCommerce',
			'iOS & Android applications',
		],
		link: {
			title: 'Discover',
			url: '/en/swiss-web-agency/',
		},
	},
];

const statisticsList = [
	{
		value: '20',
		text:
			'Specialists, forming a talented team capable of making any project a success.',
		link: { title: 'Meet the team', url: '/en/team/' },
	},
	{
		modifiers: 'award',
		value: '20',
		text:
			'International awards that celebrate our qualitative approach and innovative solutions.',
		awards: [
			'6x Site of The Day – CSS Design Awards',
			'2x Site of The Day – Awwwards',
			'2x Developper Award – Awwwards',
			'8x Honorable Mentions – Awwwards',
		],
	},
	{
		value: '8',
		text:
			'Values that inspire our day-to-day actions. Discover our agency’s culture.',
		link: { title: 'Learn more', url: '/en/culture/' },
	},
];

// --------------------------------
// #endregion
// --------------------------------

const AgencyPage = (props) => (
	<Layout
		language={language}
		pageClass={pageClass}
		footerRelatedLinks={footerRelatedLinks}
		location={props.location}
		translations={[{ uri: '/fr/agence/', lang: 'fr' }]}
		title="Agency - Superhuit"
	>
		<HeroPage image={imgEight} title={'We amplify'} />

		<section
			className="grid-content grid text-content"
			aria-label="About"
			data-animation-page
		>
			<h2>Superhuit is a branding and digital design agency</h2>
			<p className="col-text">
				Driven by the quest for human and digital progress, our mission
				is to amplify the impact of your business, brands and ideas. You
				bring us your project, we approach it with a design methodology
				infused by a strong, unique and intangible personality.
			</p>
			<p className="col-text">
				The result? Your business will transform into an interactive
				brand that people love online and beyond. Your horizon of
				possibilities will be forever broadened. And you'll have the
				tools so your business can keep up with the world of today.
			</p>
		</section>

		<section className="grid" aria-label="Statistics">
			<Panel
				className={['col-start-2', 'col-end-12']}
				statisticsList={statisticsList}
				data-animation-page
			/>
		</section>

		<section className="grid" aria-label="Services">
			<div className="box text-content text-center" data-animation-page>
				<p className="uptitle">Our services</p>
				<div className="list-three-columns">
					{servicesList.map((service, index) => (
						<ItemBullet
							key={`service-${index}`}
							titleTag="h3"
							{...service}
						/>
					))}
				</div>
			</div>
		</section>

		<section className="grid" aria-label="Clients">
			<div className="box text-content text-center" data-animation-page>
				<p className="uptitle">We work with</p>
				<ListClients clientsList={clientsList} />
			</div>
			<Cta
				text="What if this is the start of a great adventure together?"
				cta="Let’s chat"
				url="#message"
			/>
		</section>

		<section aria-label="Network">
			<SectionTitle title="The power of collaboration" />
			<div className="grid" data-animation-page>
				<div className="text-content box">
					<p className="uptitle">Network</p>
					<h2>We believe in the power of collaboration</h2>
					<div className="list-three-columns align-items-center">
						<ItemLogo
							modifiers="small"
							image={{
								src: imgMessieurs,
								alt: 'Messieurs',
							}}
						/>
						<ItemLogo
							modifiers="small"
							image={{
								src: imgSigma_legal,
								alt: 'Sigma',
							}}
							style={{
								justifyContent: 'center',
								// paddingRight: '25%', // hack for visual balance
							}}
							imgStyle={{
								maxHeight: '90px', // override
							}}
						/>
						{/* <ItemLogo
							modifiers="small"
							image={{
								src: imgRamon_pedro,
								alt: 'Ramon+Pedro',
							}}
						/> */}
					</div>
				</div>
			</div>
			<div
				className="process grid-content grid text-content"
				data-animation-page
			>
				<h2 className="title-big">We amplify</h2>
				<p className="col-text">
					<b>Amplify, amplify, amplify</b> – our ultimate goal is to
					help you grow your business using our analytical design and
					simplification process. In short, we amplify your impact.
				</p>
				<div className="list-three-columns list-three-columns--small">
					<ItemImage
						image={imgAnalyser}
						title="Analyze"
						text="to start off on the right foot"
					/>
					<ItemImage
						image={imgSimplifier}
						title="Simplify"
						text="to highlight what matters"
					/>
					<ItemImage
						image={imgAmplifier}
						title="Amplify"
						text="your business’ impact"
					/>
				</div>
			</div>
		</section>

		<section aria-label="Difference">
			<SectionTitle title="We are a category of one" />

			<div className="grid" data-animation-page>
				<div className="text-content box">
					<p className="uptitle">No comparison</p>
					<h2>We are a category of one</h2>
					<ul className="list-two-columns">
						<li>
							<h4>Custom-made</h4>
							<p>
								Artists at heart, we offer bespoke solutions.
								And like the haute couture designer, we create
								experiences that respond to overall needs while
								being sensitive to the key details.
								International recognition doesn’t happen by
								chance – it’s the result of our unique approach.
							</p>
						</li>
						<li>
							<h4>One project = One team</h4>
							<p>
								From discovery to production, we carefully
								assign a winning team to each project. We
								understand what’s at stake so we stand back to
								get a full perspective and then handpick the
								team with the right mix of technical, design and
								strategy skills.
							</p>
						</li>
						<li>
							<h4>Close interactions</h4>
							<p>
								At superhuit, we don’t do one-size-fits-all. We
								ask you the right questions to better understand
								your needs so we can create meaningful end
								products. We go to great lengths to nurture our
								relationship with you. Because our common ground
								is the fertile soil where magic grows!
							</p>
						</li>
						<li>
							<h4>Cooperative</h4>
							<p>
								We thrive on long-term relationships. In fact,
								you won't catch us doing a runner after the
								launch of your project; we keep our finger on
								the pulse and continue to work closely with you
								as our partnership evolves. When you love
								something, you don't leave. So we don't.
							</p>
						</li>
						<li>
							<h4>Making excuses isn’t on our radar</h4>
							<p>
								From pre-planning through project delivery, we
								tirelessly insist on maintaining open and frank
								channels of communication. You have our word: no
								bull$#*!. In a nutshell, this is what delivering
								successful projects is all about.
							</p>
						</li>
						<li>
							<h4>No lock-ins</h4>
							<p>
								Trust is our watchword. Your project blue-prints
								will be handed over to you so you can continue
								to leverage your investment. No questions asked.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<section className="grid" aria-label="Send Message" id="message">
			<FormMessage
				title="Keen to find out how we can assist you?"
				text="Email us at"
				contact="hello@superhuit.ch"
			/>
		</section>
	</Layout>
);

export default AgencyPage;
